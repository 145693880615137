import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  List,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Stack } from '@mui/system';

const Facet = ({
  facetsData,
  handleChange,
  selectedValues,
  label,
  isInitiallyExpanded,
}) => {
  const [showFilters, setShowFilters] = useState(isInitiallyExpanded);
  const [localOpenFacets, setLocalOpenFacets] = useState({});

  const handleLocalToggleFacet = (category) => {
    setLocalOpenFacets((prevOpenFacets) => ({
      ...prevOpenFacets,
      [category]: !prevOpenFacets[category],
    }));
  };

  const handleSelect = (category, value) => {
    handleChange(category, value);
  };

  useEffect(() => {
    if (isInitiallyExpanded) {
      const initialOpenFacets = {};
      facetsData.forEach((facet) => {
        if (
          facet.category === 'Sections' ||
          facet.category === 'Courts' ||
          facet.category === 'Year'
        ) {
          initialOpenFacets[facet.category] = true;
        }
      });
      setLocalOpenFacets(initialOpenFacets);
    }
  }, [facetsData, isInitiallyExpanded]);

  return (
    <Box sx={{ mb: 2 }}>
      <ListItem button onClick={() => setShowFilters(!showFilters)}>
        <ListItemText
          primary={<Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>}
        />
        {showFilters ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={showFilters} timeout="auto" unmountOnExit>
        {facetsData.map((facet) => (
          <Box key={facet.category} sx={{ mb: 2 }}>
            <ListItem
              button
              onClick={() => handleLocalToggleFacet(facet.category)}
            >
              <ListItemText primary={facet.category} />
              {localOpenFacets[facet.category] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
            <Collapse
              in={localOpenFacets[facet.category]}
              timeout="auto"
              unmountOnExit
            >
              {facet.category === 'Sections' && (
                <List component="div" disablePadding>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', pl: 4 }}>
                    {facet.options.map((option) => (
                      <Box key={option} sx={{ width: '50%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedValues[facet.category]?.includes(
                                option
                              )}
                              onChange={() =>
                                handleChange(facet.category, option)
                              }
                            />
                          }
                          label={
                            <Typography variant="body2">{option}</Typography>
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                </List>
              )}
              {facet.category === 'Courts' && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 2,
                  }}
                >
                  <Autocomplete
                    size="small"
                    value={selectedValues[facet.category] || ''}
                    onChange={(event, newValue) =>
                      handleSelect(facet.category, newValue)
                    }
                    options={facet.options || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={facet.category}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          width: '95%',
                          ml: '5px',
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              {facet.category === 'Year' && (
                <Stack direction="row">
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      size="small"
                      select
                      label="Starting Year"
                      sx={{ width: '95%', ml: '5px' }}
                      value={selectedValues['startingYear'] || ''}
                      onChange={(event) =>
                        handleSelect('startingYear', event.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    >
                      {facet.options
                        .sort((a, b) => parseInt(a) - parseInt(b))
                        .map((option) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      size="small"
                      select
                      label="Ending Year"
                      sx={{ width: '95%', ml: '5px' }}
                      value={selectedValues['endingYear'] || ''}
                      onChange={(event) =>
                        handleSelect('endingYear', event.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    >
                      {facet.options
                        .sort((a, b) => parseInt(a) - parseInt(b))
                        .filter((option) =>
                          selectedValues['startingYear']
                            ? option >= selectedValues['startingYear']
                            : option
                        )
                        .map((option) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                </Stack>
              )}
              {facet.category !== 'Sections' &&
                facet.category !== 'Courts' &&
                facet.category !== 'Year' && (
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <Autocomplete
                      size="small"
                      value={selectedValues[facet.category] || ''}
                      onChange={(event, newValue) =>
                        handleSelect(facet.category, newValue)
                      }
                      options={facet.options || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={facet.category}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            width: '95%',
                            ml: '5px',
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
            </Collapse>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default Facet;
