import { Box } from '@mui/material';
import React from 'react';
import HomeSidebar from '../components/layout/HomeSidebar';
import LegalChatHomePage from './LegalChatHomePage';

const HomePage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      }}
    >
      <HomeSidebar />
      <LegalChatHomePage />
    </Box>
  );
};

export default HomePage;
