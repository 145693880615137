export const petitionHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PETITION_HISTORY_REQUEST':
      return { loading: true };
    case 'PETITION_HISTORY_SUCCESS':
      return {
        loading: false,
        history: action.payload,
        historyReset: false,
        chatId: action.payload.chatId,
      };
    case 'PETITION_HISTORY_FAIL':
      return { loading: false, error: action.payload };
    case 'PETITION_HISTORY_RESET':
      return {};
    default:
      return state;
  }
};

export const editPetitionTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case 'EDIT_PETITION_TITLE_REQUEST':
      return { loading: true };
    case 'EDIT_PETITION_TITLE_SUCCESS':
      return { loading: false, message: action.payload, success: true };
    case 'EDIT_PETITION_TITLE_FAIL':
      return { loading: false, error: action.payload };
    case 'EDIT_PETITION_TITLE_RESET':
      return {};
    default:
      return state;
  }
};

export const deletePetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'DELETE_PETITION_REQUEST':
      return { loading: true };
    case 'DELETE_PETITION_SUCCESS':
      return { loading: false, message: action.payload, success: true };
    case 'DELETE_PETITION_FAIL':
      return { loading: false, error: action.payload };
    case 'DELETE_PETITION_RESET':
      return {};
    default:
      return state;
  }
};

export const newPetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'NEW_PETITION_REQUEST':
      return { loading: true };
    case 'NEW_PETITION_SUCCESS':
      return { loading: false, isClicked: true };
    case 'NEW_PETITION_FAIL':
      return { loading: false, error: action.payload };
    case 'NEW_PETITION_RESET':
      return {};
    default:
      return state;
  }
};

export const petitionTitlesReducer = (state = [], action) => {
  switch (action.type) {
    case 'GET_PETITION_TITLES_REQUEST':
      return { loading: true };
    case 'GET_PETITION_TITLES_SUCCESS':
      return { loading: false, titles: action.payload };
    case 'GET_PETITION_TITLES_FAIL':
      return { loading: false, error: action.payload };
    case 'GET_PETITION_TITLES_RESET':
      return {};
    default:
      return state;
  }
};
