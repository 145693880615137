import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Box,
  Stack,
  Drawer,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Sidebar from './components/layout/Sidebar';
import ChatInterface from './components/ChatInterface';
import { initializeGA, trackPageView } from './analytics';
import NavigationPane from './components/layout/NavigationPane';
import NotFound from './components/NotFound';
import PetitionSidebar from './components/layout/PetitionSidebar';
import PetitionForm from './components/PetitionForm';
import LegalSearchSidebar from './components/layout/LegalSearchSidebar';
import LegalSearch2 from './components/LegalSearch2';
import SearchResults from './components/SearchResults';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { searchResults } from './actions/searchActions';
import CaseDetailSidebar2 from './components/layout/CaseDetailSidebar2';
import CaseDetail2 from './components/CaseDetail2';
import CaseDetailRightSidebar from './components/layout/CaseDetailRightSidebar';
import FlexNavPane from './components/layout/FlexNavPane';
import HomeSidebar from './components/layout/HomeSidebar';

const queryClient = new QueryClient();
initializeGA();

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [selectedOption, setSelectedOption] = useState('caseLaw');
  const [primarySearchValue, setPrimarySearchValue] = useState('');
  const [selectedSection, setSelectedSection] = useState('Case Description');
  const [selectedItem, setSelectedItem] = useState('Legal Chat');
  const [currentView, setCurrentView] = useState('homepage'); // or 'module'

  const handleItemClick = (label) => {
    setSelectedItem(label);
    // Navigate or update content based on selected item
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePrimarySearchValueChange = (e) => {
    setPrimarySearchValue(e.target.value);
  };

  const handleResetPrimarySearchValue = () => {
    setPrimarySearchValue('');
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (!primarySearchValue) {
        return;
      } else {
        dispatch(searchResults(primarySearchValue));
      }
    }
  };

  const handleAppliedFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const updateSearchBar = (mode, value) => {
    setSelectedOption(mode);
    setPrimarySearchValue(value);
  };

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        {location.pathname === '/app/searchResults' && (
          <Box
            sx={{
              display: 'flex',
              marginLeft: '55px',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
              //borderRadius: '24px',
              borderBottom: '1px solid #ccc',
            }}
          >
            <TextField
              variant="outlined"
              autoFocus
              placeholder="What are you looking for?"
              value={primarySearchValue}
              onChange={handlePrimarySearchValueChange}
              onKeyDown={handleEnter}
              sx={{
                width: '95%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '24px',
                  paddingRight: '0px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <FormControl
                      variant="outlined"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '56px',
                      }}
                    >
                      <Select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          width: '100%',
                          height: '100%',
                          bgcolor: 'transparent',
                          '.MuiSelect-select': {
                            paddingRight: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#303f9f',
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        }}
                        IconComponent={ArrowDropDownIcon}
                      >
                        <MenuItem value="caseLaw">Case Law</MenuItem>
                        {/* <MenuItem value="statutes">Statutes</MenuItem> */}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        <Stack direction="row">
          {isSidebarVisible && location.pathname === '/app/legal-chat' && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              sx={{
                '& .MuiDrawer-paper': {
                  width: '80%',
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
              onClose={toggleSidebarVisibility}
            >
              <Sidebar isVisible={isSidebarVisible} />
            </Drawer>
          )}
          {location.pathname === '/app/case-law' ? (
            <HomeSidebar />
          ) : (
            <NavigationPane />
          )}

          {location.pathname === '/app/legal-chat' && <Sidebar />}
          {location.pathname === '/app/petition' && <PetitionSidebar />}
          {location.pathname === '/app/searchResults' && (
            <LegalSearchSidebar
              handleFilters={handleAppliedFilters}
              searchQuery={primarySearchValue}
              resetQuery={handleResetPrimarySearchValue}
            />
          )}
          {location.pathname === '/app/case-detail' && (
            <CaseDetailSidebar2 onSectionClick={handleSectionClick} />
          )}
          {location.pathname === '/app/legal-chat' && (
            <ChatInterface toggleSidebar={toggleSidebarVisibility} />
          )}
          {location.pathname === '/app/case-management' && <NotFound />}
          {location.pathname === '/app/petition' && <PetitionForm />}
          {location.pathname === '/app/case-law' && (
            <LegalSearch2
              filters={appliedFilters}
              updateSearchBar={updateSearchBar}
            />
          )}
          {location.pathname === '/app/searchResults' && (
            <SearchResults filters={appliedFilters} />
          )}
          {location.pathname === '/app/case-detail' && (
            <CaseDetail2 selectedSection={selectedSection} />
          )}
          {location.pathname === '/app/case-detail' && (
            <CaseDetailRightSidebar />
          )}
        </Stack>
      </Box>
    </QueryClientProvider>
  );
}

export default App;
