// DropdownButton.js
import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Chip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/system';

const DropdownButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(2); // Default to the second option

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', mr: 2 }}>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          textTransform: 'none',
          borderRadius: 2,
          borderColor: selectedOption === 2 ? 'primary.main' : 'grey.500',
          color: selectedOption === 2 ? 'primary.main' : 'text.primary',
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
      >
        LawGPT
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3,
            minWidth: 200,
            '& .MuiMenuItem-root': {
              padding: '12px 16px',
              borderRadius: 2,
              '&:hover': {
                backgroundColor: '#F5F5F5',
              },
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleSelectOption(1)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ textTransform: 'none' }}>LawGPT Premium</Typography>
          <IconButton size="small" color="primary">
            <Chip
              label="Upgrade"
              sx={{ bgcolor: '#213165', ml: 1 }}
              color="primary"
            />
          </IconButton>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleSelectOption(2)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ textTransform: 'none' }}>LawGPT Basic</Typography>
          <IconButton size="small" color="primary">
            <CheckCircleIcon sx={{ color: '#213165' }} />
          </IconButton>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DropdownButton;
