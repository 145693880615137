// Format Reference Labels
// export const formatRefLabel = (str) => {
//   return str.split(',').join(', ');
// };

// List of prepositions to keep lowercase
const prepositions = [
  'to',
  'of',
  'through',
  'in',
  'on',
  'at',
  'with',
  'by',
  'for',
  'about',
  'against',
  'between',
  'into',
  'during',
  'without',
  'before',
  'under',
  'around',
  'among',
];

const isRomanNumeral = (word) => {
  const romanRegex =
    /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/i;
  return romanRegex.test(word);
};

export const formatRefLabel = (input) => {
  return input
    .split(',')
    .map((part) =>
      part
        .trim()
        .split(' ')
        .map((word, index) => {
          const lowerCaseWord = word.toLowerCase();
          if (
            index !== 0 &&
            prepositions.includes(lowerCaseWord) &&
            !isRomanNumeral(word)
          ) {
            return lowerCaseWord;
          }
          return isRomanNumeral(word)
            ? word.toUpperCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ')
    )
    .join(', ');
};

export const wait = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
};
