import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  TextField,
  Stack,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { logout } from '../actions/userActions';
import ChatModeBox from '../components/UI/ChatModeBox';
import DropdownButton from '../components/UI/DropdownButton';
import { getChatTitles } from '../actions/chatActions';
import config from '../config/api';
import { trackEvent, trackEventWithResponseTime } from '../analytics';
import { ToastContainer, toast } from 'react-toastify';

const LegalChatHomePage = () => {
  const theme = useTheme();
  const messageEndRef = useRef(null);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mode, setMode] = useState('balanced');
  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [finalChatId, setFinalChatId] = useState('');
  const [response, setResponse] = useState('');
  const [chatTitle, setChatTitle] = useState('');
  const [alignment, setAlignment] = useState('balanced');

  const { instance, accounts } = useMsal();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chatHistory = useSelector((state) => state.chatHistory);
  const { loading: historyLoading, history } = chatHistory;

  const newChat = useSelector((state) => state.newChat);
  const { isClicked } = newChat;

  const editChat = useSelector((state) => state.editChat);
  const { success: successEdit } = editChat;

  const deleteChat = useSelector((state) => state.deleteChat);
  const { success: successDelete } = deleteChat;

  // Function to find the AccountInfo based on homeAccountId
  const findAccountByHomeAccountId = (homeAccountId) => {
    return accounts.find((account) => account.homeAccountId === homeAccountId);
  };

  const signOutClickHandler = (instance, accountToSignOut) => {
    instance.logout({
      account: accountToSignOut,
      postLogoutRedirectUri: '/',
    });
  };

  const logOut = () => {
    const homeAccountId = accounts[0]?.homeAccountId;
    const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
    if (accountToSignOut) {
      signOutClickHandler(instance, accountToSignOut);
    } else {
      console.error('Account not found for the given homeAccountId');
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    logOut();
  };

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const generateChatbotResponse = async (userMessage) => {
    let keyword;
    let eventAction = '';

    if (alignment === 'balanced') {
      keyword = 'statutes_gen';
      eventAction = 'Free Form Query';
    }
    if (alignment === 'precise') {
      keyword = 'precise_gen';
      eventAction = 'Structured Query';
    }
    if (alignment === 'case law') {
      keyword = 'cases_retrieval';
      eventAction = 'Case Law Query';
    }
    //const endpoint = `${config.apiUrl}/${keyword}?query=${userMessage}&user_id=${userInfo._id}&chat_id=${finalChatId}`;
    const balancedAzureFn = `${config.apiUrl}/${keyword}`;
    const data = {
      user_id: userInfo._id,
      chat_id: finalChatId,
      temperature: 'precise',
      answer_length: 'high',
      query: userMessage,
    };

    const startTime = new Date(); // Record start time before sending the query

    trackEvent('Chat', 'UserQuery', `User: ${userInfo.name}`); // for google analytics track

    try {
      setIsLoading(true);
      const response = await fetch(balancedAzureFn, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      const endTime = new Date();
      const responseTimeMilliseconds = endTime - startTime;

      console.log(response);
      if (!response.ok) {
        throw new Error('Failed to response');
      }

      trackEvent('Chat', eventAction, `User: ${userInfo.name}`); //tracking the query type
      trackEventWithResponseTime(
        'Chat',
        'Response Time',
        `User Message: ${userMessage}`,
        responseTimeMilliseconds
      ); //tracking the response time

      return await response.json();
    } catch (error) {
      toast.error('Something went wrong. Please try again later.', {
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    // Ignore empty messages
    if (inputMessage.trim() === '') return;
    // Message limit implementation
    // if (messages?.length > 1) {
    //   setOpenAlertDialog(true);
    //   return;
    // }
    trackEvent('Chat', 'Chat Initiated', `User: ${userInfo.name}`); // for google analytics track
    // if (alignment === 'precise') {
    //   const inputPattern1 = /^[0-9]{2}[A-Z]\.\d\.[a-z]$/;
    //   const inputPattern2 = /\d+(\.\d+)?/g;
    //   if (
    //     !inputPattern1.test(inputMessage) &&
    //     !inputPattern2.test(inputMessage)
    //   ) {
    //     toast.error('Invalid input format', { position: 'top-right' });
    //     return;
    //   }
    //   var articleNumber =
    //     inputMessage.match(inputPattern1) || inputMessage.match(inputPattern2);

    //   if (!articleNumber) {
    //     toast.error('Invalid input format', { position: 'top-right' });
    //     return;
    //   }
    //   var query = `search ${selectedBook?.code} ${articleNumber}`;
    //   var prompt = `search ${selectedBook?.code}: ${articleNumber}`;
    // }
    // Add user message to the chat
    const newMessages = [
      ...(messages || []),
      { text: { response: prompt || inputMessage }, isUser: true },
    ];
    setMessages(newMessages);
    setInputMessage('');

    // Simulate a response from the chatbot (you can replace this with your actual logic)
    const chatbotResponse = await generateChatbotResponse(inputMessage);
    // const chatbotResponse = {
    //   response: "This is a system generated response",
    //   role: "model",
    // };
    if (chatbotResponse) {
      const updatedMessages = [
        ...newMessages,
        {
          text: chatbotResponse,
          isUser: false,
        },
      ];
      setMessages(updatedMessages);
    }
    if (messages.length <= 1) {
      dispatch(getChatTitles(userInfo?._id));
      updateChatTitle();
    }
    // Clear the input field
    //setInputMessage("");
  };

  const updateChatTitle = useCallback(async () => {
    const endpoint = `${config.apiUrl}/get_chat_title`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ user_id: userInfo._id, chat_id: finalChatId }),
    });
    if (response.ok) {
      let title = await response.json();
      setChatTitle(title);
    }
  }, [userInfo, finalChatId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 5,
        maxHeight: '100vh',
        p: '2px 8px 0px 8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {location.pathname === '/home' && <DropdownButton />}
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Typography>{userInfo?.name}</Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              sx={{ width: '40px', height: '40px' }}
              alt="user"
              src="/images/userImage.png"
            />
          </IconButton>
          <Menu
            sx={{ marginTop: 5 }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '55%',
          flexDirection: 'column',
          marginTop: '10%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 1.5,
          }}
        >
          <img
            src="/images/legalSearchLogo2.png"
            alt="product logo"
            style={{
              maxWidth: '180px',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box>
        <Typography variant="h6" mb="12px">
          How can I help you today?
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {['Freeform Legal Inquiry', 'Structured Query', 'Case Law Query'].map(
            (mode, index) => (
              <ChatModeBox
                key={index}
                text={mode}
                isSelected={selectedIndex === index}
                onClick={() => setSelectedIndex(index)}
              />
            )
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: '2%',
          justifyContent: 'center',
        }}
      >
        <TextField
          sx={{
            whiteSpace: 'normal',
            width: '59%',
            boxShadow: 3,
            borderRadius: 8,
            color: '#003D78',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#101936',
              },
              '&:hover fieldset': {
                borderColor: '#3C4A73',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1F2A49',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#003D78',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#1F2A49',
            },
          }}
          variant="outlined"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={inputMessage}
          placeholder={mode === 'precise' ? '' : 'Ask any question...'}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            sx: { borderRadius: 8, p: 0 },
            endAdornment: (
              <Stack
                sx={{
                  paddingLeft: 1,
                  paddingRight: 1,
                  height: '100%',
                  borderRadius: '0px 64px 64px 0px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#303f9f',
                }}
              >
                <IconButton
                  sx={{ color: '#fff' }}
                  variant="contained"
                  onClick={handleSendMessage}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default LegalChatHomePage;
