import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const HomeSidebar = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('');
  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === 'Legal Chat') {
      navigate('/app/legal-chat');
    } else if (item === 'Legal Search') {
      navigate('/app/case-law');
    } else if (item === 'Case Management') {
      navigate('/app/case-management');
    } else if (item === 'Bookmarks') {
      navigate('/app/bookmarks');
    } else {
      navigate('/app/petition');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: '278px',
        minHeight: '99.5vh',
        p: '5px 8px 0 8px',
        backgroundColor: '#101936',
        borderRight: '1px solid #ccc',
        maxHeight: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mt: 1.5,
          mb: 2,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/home')}
      >
        <img
          src="/images/lawgpt.png"
          alt="LawGPT"
          width="125px"
          height="auto"
        />
      </Box>
      <List>
        {[
          { label: 'Legal Chat', icon: <ChatIcon /> },
          { label: 'Legal Search', icon: <SearchIcon /> },
          { label: 'Bookmarks', icon: <BookmarkIcon />, disabled: true },
          { label: 'Petition Management', icon: <AssignmentIcon /> },
          {
            label: 'Case Management',
            icon: <BusinessCenterIcon />,
            disabled: true,
          },
        ].map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              onClick={() => handleItemClick(item.label)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 1,
                color: selectedItem === item.label ? 'primary.main' : 'white',
                backgroundColor:
                  selectedItem === item.label ? '#303f9f' : 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                borderRadius: 1,
                padding: 1,
              }}
              disabled={item.disabled}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  color: 'white',
                  mr: 3,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  color: selectedItem === item.label ? 'primary.main' : 'white',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default HomeSidebar;
