import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PetitionEditor = ({ initialContent, onSave }) => {
  const [content, setContent] = useState(initialContent);

  const handleChange = (value) => {
    setContent(value);
    onSave(value);
  };

  return (
    <ReactQuill
      value={content}
      onChange={handleChange}
      modules={PetitionEditor.modules}
      formats={PetitionEditor.formats}
      theme="snow"
    />
  );
};

PetitionEditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['link', 'image'],
    ['clean'],
  ],
};

PetitionEditor.formats = [
  'header',
  'font',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'align',
  'color',
  'background',
  'link',
  'image',
];

export default PetitionEditor;
