import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Box,
  Avatar,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Typography,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Chip,
  useTheme,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { color, Stack } from '@mui/system';
//import CachedIcon from '@mui/icons-material/Cached';
import MenuIcon from '@mui/icons-material/Menu';
import IosShareIcon from '@mui/icons-material/IosShare';
//import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/api';
import { getChatTitles } from '../actions/chatActions';
import BookListingDialog from './BookListingDialog';
import ResizablePreview from './ResizeablePreview';
import AlertDialog from './AlertDialog';
import Feedback from './Feedback';
import { trackEvent, trackEventWithResponseTime } from '../analytics';
import { formatRefLabel, wait } from '../utils/textFormatting';
import styled from 'styled-components';


// const simplifyResponse = async (userId, chatId) => {
//   const endpoint = `${config.apiUrl}/simplify_last_response?user_id=${userId}&chat_id=${chatId}`;
//   const response = await fetch(endpoint, { method: 'GET' });
//   if (!response.ok) {
//     throw new Error('Failed to simplify');
//   }
//   return await response.json();
// };

// const regenerateResponse = async (userId, chatId) => {
//   const endpoint = `${config.apiUrl}/regenerate_last_response?user_id=${userId}&chat_id=${chatId}`;
//   const response = await fetch(endpoint, {
//     method: 'GET',
//   });
//   if (!response.ok) {
//     throw new Error('Failed to regenerate');
//   }
//   return await response.json();
// };

// Default Function Component
const ChatInterface = ({ toggleSidebar }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [chatTitle, setChatTitle] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [finalChatId, setFinalChatId] = useState('');
  const [openListingDialog, setOpenListingDialog] = useState(false);
  const [visibleReference, setVisibleReference] = useState({});
  const [alignment, setAlignment] = useState('balanced');
  const [selectedBook, setSelectedBook] = useState(null);
  const [documentSrc, setDocumentSrc] = useState('');
  const [isHtml, setIsHtml] = useState(false);
  const [textToHighlight, setTextToHighlight] = useState('');
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [response, setResponse] = useState('');
  const [feedbackQuery, setFeebackQuery] = useState('');
  const [feedbackRefs, setFeedbackRefs] = useState([]);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [messageIndex, setMessageIndex] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  //const [isRefClicked, setIsRefClicked] = useState(false);

  const chatContentRef = useRef(null);
  const messageEndRef = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chatHistory = useSelector((state) => state.chatHistory);
  const { loading: historyLoading, history } = chatHistory;

  const newChat = useSelector((state) => state.newChat);
  const { isClicked } = newChat;

  const editChat = useSelector((state) => state.editChat);
  const { success: successEdit } = editChat;

  const deleteChat = useSelector((state) => state.deleteChat);
  const { success: successDelete } = deleteChat;

  // const {
  //   data: simplifyData,
  //   error: errorSimplify,
  //   refetch: refetchSimplify,
  // } = useQuery(
  //   ['simplify', userInfo?._id, finalChatId],
  //   () => simplifyResponse(userInfo?._id, finalChatId),
  //   {
  //     enabled: false,
  //   }
  // );

  // const {
  //   data: regenerateData,
  //   error: errorRegenerate,
  //   refetch: refetchRegenerate,
  // } = useQuery(
  //   ['regenerate', userInfo?._id, finalChatId],
  //   () => regenerateResponse(userInfo?._id, finalChatId),
  //   {
  //     enabled: false,
  //   }
  // );

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const handleClosePreview = () => {
    setDocumentSrc(null);
  };

  const handleCloseListingDialog = () => {
    setOpenListingDialog(false);
  };

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleFeedbackGiven = (messageIndex) => {
    setFeedbackGiven({ ...feedbackGiven, [messageIndex]: true });
  };

  const handleSendMessage = async () => {
    // Ignore empty messages
    if (inputMessage.trim() === '') return;
    // Message limit implementation
    // if (messages?.length > 1) {
    //   setOpenAlertDialog(true);
    //   return;
    // }
    trackEvent('Chat', 'Chat Initiated', `User: ${userInfo.name}`); // for google analytics track
    if (alignment === 'precise') {
      const inputPattern1 = /^[0-9]{2}[A-Z]\.\d\.[a-z]$/;
      const inputPattern2 = /\d+(\.\d+)?/g;
      if (
        !inputPattern1.test(inputMessage) &&
        !inputPattern2.test(inputMessage)
      ) {
        toast.error('Invalid input format', { position: 'top-right' });
        return;
      }
      var articleNumber =
        inputMessage.match(inputPattern1) || inputMessage.match(inputPattern2);

      if (!articleNumber) {
        toast.error('Invalid input format', { position: 'top-right' });
        return;
      }
      var query = `search ${selectedBook?.code} ${articleNumber}`;
      var prompt = `search ${selectedBook?.code}: ${articleNumber}`;
    }
    // Add user message to the chat
    const newMessages = [
      ...(messages || []),
      { text: { response: prompt || inputMessage }, isUser: true },
    ];
    setMessages(newMessages);
    setInputMessage('');

    // Simulate a response from the chatbot (you can replace this with your actual logic)
    const chatbotResponse = await generateChatbotResponse(
      query || inputMessage
    );
    // const chatbotResponse = {
    //   response: "This is a system generated response",
    //   role: "model",
    // };
    if (chatbotResponse) {
      const updatedMessages = [
        ...newMessages,
        {
          text: chatbotResponse,
          isUser: false,
        },
      ];
      setMessages(updatedMessages);
    }
    if (messages.length <= 1) {
      dispatch(getChatTitles(userInfo?._id));
      updateChatTitle();
    }
    // Clear the input field
    //setInputMessage("");
  };

  const generateChatbotResponse = async (userMessage) => {
    let keyword;
    let eventAction = '';

    if (alignment === 'balanced') {
      keyword = 'statutes_gen';
      eventAction = 'Free Form Query';
    }
    if (alignment === 'precise') {
      keyword = 'precise_gen';
      eventAction = 'Structured Query';
    }
    if (alignment === 'case law') {
      keyword = 'cases_retrieval';
      eventAction = 'Case Law Query';
    }
    //const endpoint = `${config.apiUrl}/${keyword}?query=${userMessage}&user_id=${userInfo._id}&chat_id=${finalChatId}`;
    const balancedAzureFn = `${config.apiUrl}/${keyword}`;
    const data = {
      user_id: userInfo._id,
      chat_id: finalChatId,
      temperature: 'precise',
      answer_length: 'high',
      query: userMessage,
    };

    const startTime = new Date(); // Record start time before sending the query

    trackEvent('Chat', 'UserQuery', `User: ${userInfo.name}`); // for google analytics track

    try {
      setIsLoading(true);
      const response = await fetch(balancedAzureFn, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      const endTime = new Date();
      const responseTimeMilliseconds = endTime - startTime;

      console.log(response);
      if (!response.ok) {
        throw new Error('Failed to response');
      }

      trackEvent('Chat', eventAction, `User: ${userInfo.name}`); //tracking the query type
      trackEventWithResponseTime(
        'Chat',
        'Response Time',
        `User Message: ${userMessage}`,
        responseTimeMilliseconds
      ); //tracking the response time

      return await response.json();
    } catch (error) {
      toast.error('Something went wrong. Please try again later.', {
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatTitle = useCallback(async () => {
    const endpoint = `${config.apiUrl}/get_chat_title`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ user_id: userInfo._id, chat_id: finalChatId }),
    });
    if (response.ok) {
      let title = await response.json();
      setChatTitle(title);
    }
  }, [userInfo, finalChatId]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleCopyClick = async (responseText) => {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(responseText);
      await wait();
      setIsCopied(false);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleExportChat = () => {
    const chatText = messages
      .map((message) => {
        return message.isUser
          ? `User: ${message.text.response}`
          : `Assistant: ${message.text.response}\nReferences:\n${
              message.text.references
                ? message.text.references
                    ?.map((ref) => {
                      return `Label: ${ref.label}\nContent: ${ref.content}\n URL: ${ref.url}`;
                    })
                    .join('\n\n')
                : 'Not Applicable'
            }`;
      })
      .join('\n');

    const blob = new Blob([chatText], { type: 'text/plain' });

    saveAs(blob, `${chatTitle}.txt`);
  };

  // const handleSimplify = async () => {
  //   try {
  //     setIsLoading(true);
  //     await refetchSimplify();
  //     if (errorSimplify) {
  //       toast.error(errorSimplify.message, {
  //         position: 'top-right',
  //       });
  //     } else {
  //       if (simplifyData?.status === 'failure') {
  //         toast.error('Cannot simplify as no last response found', {
  //           position: 'top-right',
  //         });
  //       } else {
  //         const updatedMessages = [
  //           ...messages,
  //           { text: simplifyData, isUser: false },
  //         ];
  //         setMessages(updatedMessages);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleRegenerate = async () => {
  //   try {
  //     setIsLoading(true);
  //     await refetchRegenerate();
  //     if (errorRegenerate) {
  //       toast.error(errorRegenerate.message, {
  //         position: 'top-right',
  //       });
  //     } else {
  //       if (regenerateData?.status === 'failure') {
  //         toast.error('Cannot regenerate as no last response found', {
  //           position: 'top-right',
  //         });
  //       } else {
  //         const updatedMessages = [
  //           ...messages,
  //           { text: regenerateData, isUser: false },
  //         ];
  //         setMessages(updatedMessages);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handlePreciseChat = (event) => {
    setOpenListingDialog(true);
  };

  const handleToggleContent = (refContent, messageIndex, refIndex) => {
    setVisibleReference((prev) => ({
      ...prev,
      [messageIndex]: {
        ...prev[messageIndex],
        [refIndex]: {
          visible: !prev[messageIndex]?.[refIndex]?.visible,
          content: refContent,
        },
      },
    }));
  };

  const handleDocumentSrc = async (event, url, text, html) => {
    event.preventDefault();
    setDocumentSrc(null);
    setLoading(true);
    if (url === 'url') {
      setIsHtml(true);
      setDocumentSrc(html);
      setLoading(false);
    } else {
      try {
        setIsHtml(false);
        const newUrl = new URL(url);
        const bookName = newUrl.searchParams.get('book_name');
        const endpoint = `${config.apiUrl}/get_book`;
        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify({ book_name: bookName }),
        });
        if (response.ok) {
          let book = await response.blob();
          setDocumentSrc(URL.createObjectURL(book));
        }
        const regex =
          /^(Section text: \(\d+\)|Section text: |Article Text: \d+-|Article Text: [a-z]-|\(\d+\))/i;
        const cleanedText = text.replace(regex, '').trim();
        const words = cleanedText.split(/\s+/);
        const wordsToHighlight = words.splice(0, 8).join(' ');
        const match = wordsToHighlight.match(/^(\S+\s+)?([\s\S]*?)(-.*)/);
        const finalText = match
          ? `${match[1] || ''}${match[2].trim()}${match[3]
              .split('-')[0]
              .trim()}-`
          : wordsToHighlight;
        setTextToHighlight(finalText);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateSelectedBook = (book) => {
    setSelectedBook(book);
  };

  const handleChangeChat = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
    if (alignment === 'balanced' || alignment === 'case law') {
      setSelectedBook(null);
    }
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleFeedbackClick = (response, references, index) => {
    const userQuery = messages[index - 1]?.text.response;
    setFeebackQuery(userQuery);
    setFeedbackRefs(references);
    setResponse(response);
    setOpenFeedbackDialog(true);
    setMessageIndex(index);
  };

  useEffect(() => {
    if (successEdit) {
      updateChatTitle();
      dispatch({ type: 'EDIT_TITLE_RESET' });
    }
  }, [successEdit, finalChatId, userInfo, dispatch, updateChatTitle]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    //chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    if (isClicked || successDelete || !history) {
      setMessages([]);
      setChatTitle('');
      setSelectedBook(null);
      setDocumentSrc('');
      dispatch({ type: 'DELETE_CHAT_RESET' });
      dispatch({ type: 'NEW_CHAT_RESET' });
    }
  }, [chatContentRef, dispatch, isClicked, successDelete, history]);

  useEffect(() => {
    if (historyLoading) {
      setIsHistoryLoading(true);
    }
  }, [historyLoading]);

  useEffect(() => {
    //Add history to chat interface
    if (history) {
      setIsHistoryLoading(false);
      dispatch({ type: 'NEW_CHAT_RESET' });
      setChatTitle(history.chat_title);
      setFinalChatId(history.chat_id);
      setAlignment('balanced');
      setSelectedBook(null);
      setDocumentSrc('');
      const historyMessages = history.messages;
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (
          firstContent.startsWith('Retrieve') ||
          firstContent.startsWith('retrieve')
        ) {
          setAlignment('case law');
        }
      }
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (firstContent.startsWith('search')) {
          setAlignment('precise');
          setSelectedBook({
            code: 'COP',
            format:
              'For article 63A, clause 1 sub-clause a, the search query would be "63A.1.a"',
            full_name: 'THE CONSTITUTION OF THE ISLAMIC REPUBLIC OF PAKISTAN',
          });
        }
      }
      const mappedMessages =
        historyMessages?.length > 0 &&
        historyMessages.map((message) => {
          return {
            text: {
              response: message.response || message.content,
              references: message.references,
            },
            isUser: message.role === 'user',
          };
        });
      setMessages(mappedMessages);
    }
  }, [history, dispatch]);

  useEffect(() => {
    const newChatId = uuidv4();

    if (!history) {
      setFinalChatId(newChatId);
      setSelectedBook(null);
      setAlignment('balanced');
    }
  }, [history, dispatch, isClicked]);

  const ScrollableDiv = styled.div`
  height: 400px;
  overflow-y: auto;
  padding: 16px;
  background: #fff;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #101936;
    border-radius: 6px;
  }
`;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '95.5vh',
          flex: 5,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            width: '100%',
            minHeight: '50px',
          }}
        >
          <IconButton
            sx={{ display: { xs: 'flex', sm: 'none' } }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              gap: '0.5rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ marginLeft: '2rem' }}>
              {chatTitle}
            </Typography>
            {history && (
              <Chip
                label={alignment}
                variant="outlined"
                size="small"
                color="primary"
              />
            )}
          </Box>
          {history && (
            <Tooltip title="Export Chat">
              <IconButton variant="contained" onClick={handleExportChat}>
                <IosShareIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <AlertDialog
          open={openAlertDialog}
          handleClose={handleCloseAlertDialog}
        />
        <Feedback
          open={openFeedbackDialog}
          modelResponse={response}
          feedbackQuery={feedbackQuery}
          references={feedbackRefs}
          handleClose={handleCloseFeedbackDialog}
          messageIndex={messageIndex}
          feedbackGiven={handleFeedbackGiven}
        />
        <ScrollableDiv
          ref={chatContentRef}
          style={{
            minHeight: '75%',
            maxHeight: '75%',
            flexGrow: 1,
            overflowY: 'auto',
            flex: 1,
          }}
        >
          {isHistoryLoading ? (
            <Box
              sx={{
                height: '30%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            messages.length > 0 &&
            messages.map((message, messageIndex) => (
              <Box
                key={uuidv4()}
                sx={{
                  padding: '0.5rem',
                  marginBottom: '0.2rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  backgroundColor: message.isUser ? '#fff' : '#f5f5f5',
                  width: '95%',
                  whiteSpace: 'pre-line',
                  transition: 'opacity 0.3s, transform 0.3s',
                  transform: 'scale(0.95)',
                  counterIncrement: 'item',
                  content: 'counter(item) ". "',
                  borderRadius: '10px',
                }}
              >
                {message.isUser ? (
                  <Avatar
                    variant="circle"
                    sx={{
                      marginRight: '8px',
                      mt: 1,
                      ml: 4,
                      border: '1px solid #ccc',
                    }}
                    src="/images/userPhoto.jpg"
                  />
                ) : (
                  <Avatar
                    variant="circle"
                    sx={{
                      marginRight: '8px',
                      mt: 1,
                      ml: 4,
                    }}
                  >
                    M
                  </Avatar>
                )}
                <Typography
                  variant="body1"
                  textAlign="justify"
                  sx={{
                    padding: '1rem',
                    color: '#565454',
                    //marginBottom: "0.5rem",
                  }}
                >
                  {message.text.response}
                  {message.text.references && (
                    <Typography sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
                      Statute References:
                    </Typography>
                  )}
                  {message.text?.references?.map((ref, refIndex) => (
                    <Box
                      key={uuidv4()}
                      sx={{
                        display: 'flex',
                        margin: '0.2rem',
                        flexDirection: 'column',
                      }}
                    >
                      <Stack display="flex" flexDirection="row" padding="0">
                        <IconButton
                          sx={{
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                          onClick={() =>
                            handleToggleContent(
                              ref.content,
                              messageIndex,
                              refIndex
                            )
                          }
                        >
                          {visibleReference[messageIndex]?.[refIndex]
                            ?.visible ? (
                            <RemoveCircleOutlineIcon fontSize="small" />
                          ) : (
                            <AddCircleOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                        <Box
                          component="a"
                          sx={{
                            color: '#007bff',
                            textDecoration: 'none',
                            textTransform: 'capitalize',
                            padding: '0.2rem',
                            // border: '1px solid #616161',
                            // borderRadius: '4px',
                            transition: 'background-color 0.3s',
                            // '&:hover': {
                            //   color: '#101936',
                            // },
                          }}
                          // onClick={(e) =>
                          //   handleDocumentSrc(
                          //     e,
                          //     ref.url,
                          //     ref.content,
                          //     ref.content_html
                          //   )
                          // }
                          // onMouseEnter={(e) => {
                          //   e.target.style.color = '#101936';
                          // }}
                          // onMouseLeave={(e) => {
                          //   e.target.style.color = '#007bff';
                          // }}
                          href={ref.url}
                        >
                          {formatRefLabel(ref.label)}
                        </Box>
                      </Stack>
                      {visibleReference[messageIndex]?.[refIndex]?.visible && (
                        <Box sx={{ display: 'flex' }}>
                          {visibleReference[messageIndex][refIndex].content}
                        </Box>
                      )}
                    </Box>
                  ))}
                  {!message.isUser && (
                    <Stack flexDirection="row" sx={{ marginTop: '10px' }}>
                      <Tooltip title={isCopied ? 'Copied' : 'Copy'} arrow>
                        <IconButton
                          onClick={() => handleCopyClick(message.text.response)}
                        >
                          {isCopied ? (
                            <CheckCircleOutlineIcon fontSize="small" />
                          ) : (
                            <ContentCopyIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Bad Response" arrow>
                        <IconButton
                          disabled={feedbackGiven[messageIndex]}
                          onClick={() =>
                            handleFeedbackClick(
                              message.text.response,
                              message.text.references,
                              messageIndex
                            )
                          }
                        >
                          <ThumbDownOffAltIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </Typography>
              </Box>
            ))
          )}
          {isLoading && (
            <Box
              display="flex"
              flexDirection="column"
              marginLeft="5%"
              marginRight="5%"
            >
              <Skeleton width="85%" />
              <Skeleton width="85%" animation="wave" />
              <Skeleton width="80%" animation={false} />
            </Box>
          )}
          <div ref={messageEndRef} />
          {/* Conditional Rendering of the Chat Toggle Buttons */}
          {messages.length === 0 && !selectedBook && !isHistoryLoading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '62.5vh',
                maxHeight: '62.5vh',
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <img
                  src="/images/legalSearchLogo2.png"
                  alt="product logo"
                  style={{
                    maxWidth: '180px',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: 635,
                }}
              >
                Choose a conversation style
              </Typography>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={alignment}
                onChange={handleChangeChat}
                sx={{ display: 'flex', gap: 2 }}
              >
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                    width: '13rem',
                    //borderColor: '#101936',
                    border: '1px solid gray',
                    borderRadius: '8px',
                    color: theme.palette.text.primary,
                    transition: 'border 0.3s ease, box-shadow 0.3s ease',
                    '&.MuiToggleButtonGroup-firstButton': {
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    },
                    '&:hover': {
                      borderColor: theme.palette.primary.dark,
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      bgcolor: 'white',
                    },
                    '&:active': {
                      borderColor: theme.palette.primary.dark,
                    },
                    '&.Mui-selected': {
                      border: `2px solid ${theme.palette.primary.main}`,
                      bgcolor: 'white',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    },
                  }}
                  value="balanced"
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 400 }}
                    title="Recommended for quick info"
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          padding: '8px',
                        },
                      },
                    }}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 12],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <img
                        src="/images/freeform.png"
                        alt="free form query"
                        width="30px"
                        height="auto"
                      />
                      <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', mt: 1, color: 'black' }}
                      >
                        Freeform Legal Inquiry
                      </Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                    width: '13rem',
                    border: '1px solid gray',
                    borderRadius: '8px',
                    color: theme.palette.text.primary,
                    transition: 'border 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      borderColor: theme.palette.primary.dark,
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      bgcolor: 'white',
                    },
                    '&:active': {
                      borderColor: theme.palette.primary.dark,
                    },
                    '&.Mui-selected': {
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderLeft: `2px solid ${theme.palette.primary.main} !important`,
                      bgcolor: 'white',
                      '&:hover': {
                        bgcolor: 'white',
                      },
                    },
                    '&.MuiToggleButtonGroup-middleButton': {
                      borderLeft: '1px solid gray',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    },
                  }}
                  value="precise"
                  onClick={handlePreciseChat}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 400 }}
                    title="Start a chat, useful for exact findings"
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          padding: '8px',
                        },
                      },
                    }}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 12],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <img
                        src="/images/structured.png"
                        alt="structured query"
                        width="30px"
                        height="auto"
                      />
                      <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', mt: 1, color: 'black' }}
                      >
                        Structured Law Query
                      </Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                    width: '13rem',
                    border: '1px solid gray',
                    borderRadius: '8px',
                    color: theme.palette.text.primary,
                    transition: 'border 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      borderColor: theme.palette.primary.dark,
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      bgcolor: 'white',
                    },
                    '&:active': {
                      borderColor: theme.palette.primary.dark,
                    },
                    '&.Mui-selected': {
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderLeft: `2px solid ${theme.palette.primary.main} !important`,
                      bgcolor: 'white',
                      '&:hover': {
                        bgcolor: 'white',
                      },
                    },
                    '&.MuiToggleButtonGroup-lastButton': {
                      borderLeft: '1px solid gray',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    },
                  }}
                  value="case law"
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 400 }}
                    title="Explore Case Law"
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          padding: '8px',
                        },
                      },
                    }}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 12],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <img
                        src="/images/caseLaw.png"
                        alt="case law query"
                        width="30px"
                        height="auto"
                      />
                      <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', mt: 1, color: 'black' }}
                      >
                        Case Law Query
                      </Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              <BookListingDialog
                open={openListingDialog}
                handleClose={handleCloseListingDialog}
                handleBookChange={updateSelectedBook}
              />
            </Box>
          )}
        </ScrollableDiv>
        {/* Container for the simplify & Regenerate Buttons */}
        {documentSrc ? (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '79.5%',
              columnGap: 1,
            }}
          >
            {alignment === 'balanced' && <Box sx={{ marginTop: '40px' }} />}
            {/* Buttons for Resizable Preview */}
            {/* {alignment === 'balanced' && (
              <>
                <Button
                  sx={{
                    borderRadius: 2,
                    color: '#003D78',
                    textTransform: 'none',
                    marginTop: '20px',
                  }}
                  variant="outlined"
                  onClick={handleSimplify}
                >
                  <FormatAlignLeftIcon fontSize="small" />
                  Simplify
                </Button>
                <Button
                  sx={{
                    borderRadius: 2,
                    color: '#003D78',
                    textTransform: 'none',
                    marginTop: '20px',
                  }}
                  variant="outlined"
                  onClick={handleRegenerate}
                >
                  <CachedIcon />
                  Regenerate
                </Button>
              </>
            )} */}
            {alignment === 'precise' && selectedBook && (
              <Button
                sx={{
                  borderRadius: 2,
                  color: '#003D78',
                  textTransform: 'none',
                }}
                variant="outlined"
                onClick={handlePreciseChat}
              >
                <ChangeCircleIcon sx={{ marginRight: '0.3rem' }} />
                Change Book
              </Button>
            )}
          </Stack>
        ) : (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '79%',
              columnGap: 1,
            }}
          >
            {(alignment === 'balanced' || alignment === 'case law') && (
              <Box sx={{ marginTop: '40px' }} />
            )}
            {/* Buttons without Resizable Preview */}
            {/* {alignment === 'balanced' && (
              <>
                <Button
                  sx={{
                    borderRadius: 2,
                    color: '#003D78',
                    textTransform: 'none',
                    marginTop: '20px',
                  }}
                  variant="outlined"
                  onClick={handleSimplify}
                >
                  <FormatAlignLeftIcon fontSize="small" />
                  Simplify
                </Button>
                <Button
                  sx={{
                    borderRadius: 2,
                    color: '#003D78',
                    textTransform: 'none',
                    marginTop: '20px',
                  }}
                  variant="outlined"
                  onClick={handleRegenerate}
                >
                  <CachedIcon />
                  Regenerate
                </Button>
              </>
            )} */}
            {alignment === 'precise' && selectedBook && (
              <Button
                sx={{
                  borderRadius: 2,
                  color: '#003D78',
                  textTransform: 'none',
                }}
                variant="outlined"
                onClick={handlePreciseChat}
              >
                <ChangeCircleIcon sx={{ marginRight: '0.3rem' }} />
                Change Book
              </Button>
            )}
          </Stack>
        )}

        <BookListingDialog
          open={openListingDialog}
          handleClose={handleCloseListingDialog}
          handleBookChange={updateSelectedBook}
        />
        {/* Container for Format text and Input field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '95%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '3px',
          }}
        >
          {selectedBook && (
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                width: '58%',
                borderRadius: '5px',
                paddingLeft: '0.5rem',
                backgroundColor: '#F5F5F5',
              }}
            >
              Input Format: {selectedBook?.format}
            </Typography>
          )}
          <TextField
            sx={{
              whiteSpace: 'normal',
              width: '59%',
              //backgroundColor: '#EDEDED',
              boxShadow: 3,
              borderRadius: 2,
              color: '#003D78',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#101936',
                },
                '&:hover fieldset': {
                  borderColor: '#3C4A73',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1F2A49',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#003D78',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#1F2A49',
              },
            }}
            label={selectedBook?.full_name}
            variant="outlined"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={inputMessage}
            placeholder={alignment === 'precise' ? '' : 'Ask any question...'}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              sx: { borderRadius: 2, p: 0 },
              endAdornment: (
                <Stack
                  sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                    height: '100%',
                    borderBottomRightRadius: 8,
                    borderTopRightRadius: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#303f9f',
                  }}
                >
                  <IconButton
                    sx={{ color: '#fff' }}
                    variant="contained"
                    onClick={handleSendMessage}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </Stack>
              ),
            }}
          />
        </Box>
        <ToastContainer />
      </Box>
      {loading && (
        <Box sx={{ flexDirection: 'column' }} display="flex" width={300}>
          <CircularProgress
            style={{ position: 'relative', top: '50%', left: '40%' }}
          />
        </Box>
      )}
      {documentSrc && (
        <Box sx={{ flexDirection: 'column' }} display="flex" width={300}>
          {isHtml ? (
            <div
              style={{ display: 'flex', paddingTop: '30px', overflow: 'auto' }}
              dangerouslySetInnerHTML={{ __html: documentSrc }}
            />
          ) : (
            <ResizablePreview
              documentSrc={documentSrc}
              text={textToHighlight}
              onClose={handleClosePreview}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ChatInterface;

ChatInterface.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
