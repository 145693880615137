import config from '../config/api';

export const searchResults = (userQuery) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_SEARCH_RESULTS_REQUEST',
    });
    const endpoint = `${config.searchUrl}/indexes/case_laws_indexing_v2/docs/search?api-version=2024-05-01-preview`;
    const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';
    const requestBody = {
      count: true,
      skip: 0,
      //top: 50,
      searchMode: 'any',
      queryType: 'simple',
      facets: [
        'Court,count:5,sort:count',
        'Year,count:5,sort:count',
        'Section,count:5,sort:count',
        'Judge_Name,count:5,sort:count',
        'Lawyer_Name,count:5,sort:count',
      ],
      search: userQuery,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'GET_SEARCH_RESULTS_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_SEARCH_RESULTS_FAIL',
      payload: 'Failed to fetch chat titles.',
    });
  }
};

export const applyFilters = (searchQuery, filters) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_SEARCH_RESULTS_REQUEST',
    });

    const formatFilterString = (filterObject) => {
      const mapping = {
        Sections: 'Section',
        Courts: 'Court',
        Year: 'Year',
        'Judge Name': 'Judge_Name',
        'Lawyer Name': 'Lawyer_Name',
      };

      const formattedFilters = Object.keys(filters).reduce((acc, key) => {
        if (key === 'startingYear' && filters['endingYear']) {
          const startYear = filters['startingYear'];
          const endYear = filters['endingYear'];
          acc.push(`(Year ge '${startYear}') and (Year le '${endYear}')`);
        } else if (key === 'endingYear') {
          // Skip the endingYear key since it's handled with startingYear
        } else {
          const newKey = mapping[key];
          const value = filters[key];

          if (Array.isArray(value) && value.length > 0) {
            acc.push(value.map((v) => `(${newKey} eq '${v}')`).join(' and '));
          } else if (!Array.isArray(value) && value) {
            acc.push(`(${newKey} eq '${value}')`);
          }
        }
        return acc;
      }, []);
      return formattedFilters.join(' and ');
    };

    const endpoint = `${config.searchUrl}/indexes/case_laws_indexing_v2/docs/search?api-version=2024-05-01-preview`;
    const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';
    const requestBody = {
      count: true,
      skip: 0,
      //top: 50,
      searchMode: 'any',
      queryType: 'simple',
      facets: [
        'Year,count:5,sort:count',
        'Section,count:5,sort:count',
        'Court,count:5,sort:count',
        'Judge_Name,count:5,sort:count',
        'Lawyer_Name,count:5,sort:count',
      ],
      filter: formatFilterString(filters),
      search: searchQuery,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'GET_SEARCH_RESULTS_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_SEARCH_RESULTS_FAIL',
      payload: 'Failed to fetch chat titles.',
    });
  }
};
