import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Divider } from '@mui/material';
import Facet from '../UI/Facet';
import { applyFilters, searchResults } from '../../actions/searchActions';

const formatCourtName = (courtName) => {
  return courtName
    .toLowerCase()
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const revertCourtName = (formattedCourtName) => {
  return formattedCourtName?.toUpperCase().replace(/\s+/g, '-');
};

// Transform retrieved facets into required format for rendering
const transformFacets = (facets) => {
  const transformed = [];

  if (facets.Section) {
    transformed.push({
      category: 'Sections',
      options: facets.Section.map((section) => section.value),
    });
  }

  if (facets.Court) {
    transformed.push({
      category: 'Courts',
      options: facets.Court.map((court) => formatCourtName(court.value)),
    });
  }

  if (facets.Year) {
    transformed.push({
      category: 'Year',
      options: facets.Year.map((year) => year.value),
    });
  }

  if (facets.Title) {
    transformed.push({
      category: 'CaseTitle',
      options: facets.Title.map((title) => title.value),
    });
  }

  if (facets.Lawyer_Name) {
    const uniqueLawyers = new Set();
    facets.Lawyer_Name.forEach((lawyer) => {
      const lawyerName =
        lawyer.value && lawyer.value.trim().length > 0
          ? lawyer.value.replace(/^,/, '').trim()
          : null;
      if (lawyerName) {
        uniqueLawyers.add(lawyerName);
      }
    });
    transformed.push({
      category: 'Lawyer Name',
      options: Array.from(uniqueLawyers),
    });
  }

  if (facets.Judge_Name) {
    transformed.push({
      category: 'Judge Name',
      options: facets.Judge_Name.map((judge) => judge.value),
    });
  }

  return transformed;
};

const LegalSearchSidebar = ({ handleFilters, searchQuery, resetQuery }) => {
  const dispatch = useDispatch();
  const [facetsData, setFacetsData] = useState([]);

  const searchResultsFilters = useSelector((state) => state.searchResults);
  const { loading, results } = searchResultsFilters;

  const [openFacets, setOpenFacets] = useState({});
  const [selectedValues, setSelectedValues] = useState({ Sections: [] });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const hasNoValues = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return true;
    }
    const { Sections, ...rest } = obj;
    const isSectionsEmpty = Array.isArray(Sections) && Sections.length === 0;
    const hasNoOtherKeys = Object.keys(rest).length === 0;
    return isSectionsEmpty && hasNoOtherKeys;
  };

  const handleApplyFilter = () => {
    console.log(selectedValues);
    setIsClicked(true);
    const updatedFilters = {
      ...selectedValues,
      Courts: revertCourtName(selectedValues.Courts),
    };
    dispatch(applyFilters(searchQuery, updatedFilters));
    handleFilters(selectedValues);
  };

  const handleClearFilters = () => {
    setSelectedValues({
      Sections: [],
    });
    handleFilters(null);
    //resetQuery();
    if (isClicked) {
      dispatch(searchResults(searchQuery));
    }
    setIsClicked(false);
  };

  const handleToggleFacet = (category) => {
    setOpenFacets((prevOpenFacets) => ({
      ...prevOpenFacets,
      [category]: !prevOpenFacets[category],
    }));
  };

  const handleChange = (category, value) => {
    if (category === 'Sections') {
      setSelectedValues((prevSelectedValues) => {
        const currentValues = prevSelectedValues[category] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value];
        return { ...prevSelectedValues, [category]: newValues };
      });
    } else if (category === 'startingYear') {
      setSelectedValues((prevState) => ({
        ...prevState,
        [category]: value,
        endingYear: parseInt(value, 10) + 5,
      }));
    } else {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [category]: value,
      }));
    }
  };

  useEffect(() => {
    if (!loading && results && results['@search.facets']) {
      const transformedFacets = transformFacets(results['@search.facets']);
      setFacetsData(transformedFacets);
    }
  }, [loading, results]);

  useEffect(() => {
    setIsFilterApplied(hasNoValues(selectedValues));
  }, [selectedValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1.2,
        minWidth: '300px',
        width: '300px',
        height: '86.5vh',
        p: '8px 8px 0 12px',
        overflowY: 'auto',
        borderRight: '1px solid grey',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      {!isFilterApplied && (
        <Box
          sx={{
            mt: '15px',
            ml: '12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            size="small"
            sx={{
              p: '1px',
              mb: '8px',
              textTransform: 'none',
              color: '#303f9f',
            }}
            onClick={handleApplyFilter}
          >
            Apply Filter
          </Button>
          <Button
            size="small"
            color="error"
            sx={{
              mb: '8px',
              p: '1px',
              textTransform: 'none',
            }}
            onClick={handleClearFilters}
          >
            Clear filter(s)
          </Button>
        </Box>
      )}

      <Facet
        facetsData={facetsData.filter((facet) =>
          ['Sections', 'Courts', 'Year'].includes(facet.category)
        )}
        openFacets={openFacets}
        handleToggleFacet={handleToggleFacet}
        handleChange={handleChange}
        selectedValues={selectedValues}
        label="Primary Filters"
        isInitiallyExpanded={true}
      />
      <Divider />
      <Facet
        facetsData={facetsData.filter((facet) =>
          [
            'CaseTitle',
            'Judge Name',
            'Lawyer Name',
            'Applicant Name',
            'Opponent Name',
            'Act/Ordinance',
          ].includes(facet.category)
        )}
        openFacets={openFacets}
        handleToggleFacet={handleToggleFacet}
        handleChange={handleChange}
        selectedValues={selectedValues}
        label="Secondary Filters"
        isInitiallyExpanded={false}
      />
    </Box>
  );
};

export default LegalSearchSidebar;
