import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  Chip,
  Stack,
  Divider,
  Pagination,
  IconButton,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import textHighlight from '../utils/textHighlight';

const SearchResults = ({ filters }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state.searchResults);
  const { loading, results } = searchResults;
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [secondarySearchValue, setSecondarySearchValue] = useState('');

  const handleSecondarySearchValueChange = (e) => {
    setSecondarySearchValue(e.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handlePageChange = (event, value) => {
    scrollToTop();
    setCurrentPage(value);
  };

  // Filter results based on the secondary search value
  const filteredResults = useMemo(() => {
    if (!secondarySearchValue) return results?.value || [];
    return results?.value.filter((caseItem) => {
      const lowerCasedSearchValue = secondarySearchValue.toLowerCase();
      return (
        caseItem.Court?.toLowerCase().includes(lowerCasedSearchValue) ||
        caseItem.Lawyer_Name?.toLowerCase().includes(lowerCasedSearchValue) ||
        caseItem.Judge_Name?.toLowerCase().includes(lowerCasedSearchValue) ||
        caseItem.Case_Description?.toLowerCase().includes(lowerCasedSearchValue)
      );
    });
  }, [results, secondarySearchValue]);

  const displayedCases = filteredResults?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const formatCourtName = (courtName) => {
    return courtName
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatCaseTitle = (title) => {
    const splitTitle = title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [results]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '83vh',
        flex: 5,
        p: 2,
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: '10%',
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 1.5 }}>
            {results && results['@odata.count']} Cases
          </Typography>
          <TextField
            placeholder="Search within results"
            variant="outlined"
            fullWidth
            focused={false}
            value={secondarySearchValue}
            onChange={handleSecondarySearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 1.5, borderRadius: 1, width: '95%', ml: 3 }}
          />
          {filters && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                p: '4px 8px 4px 16px',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  mb: 1.5,
                }}
              >
                <Typography variant="subtitle1">Applied Filters:</Typography>
                {Object.entries(filters).map(([category, values]) => {
                  if (category === 'startingYear' && values) {
                    return (
                      <Chip
                        key="startingYear"
                        label={`Start Year - ${values}`}
                        sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                        color="primary"
                      />
                    );
                  } else if (category === 'endingYear' && values) {
                    return (
                      <Chip
                        key="endingYear"
                        label={`End Year - ${values}`}
                        sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                        color="primary"
                      />
                    );
                  } else if (Array.isArray(values) && values.length > 0) {
                    return values.map((value) => (
                      <Chip
                        key={`${category}-${value}`}
                        label={value}
                        sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                        color="primary"
                      />
                    ));
                  } else if (!Array.isArray(values) && values) {
                    return (
                      <Chip
                        key={`${category}-${values}`}
                        label={values}
                        sx={{ bgcolor: '#213165', borderRadius: '8px' }}
                        color="primary"
                      />
                    );
                  }
                  return null;
                })}
              </Stack>
              <Divider />
            </Box>
          )}
          <List>
            {displayedCases.map((caseItem) => (
              <ListItem key={caseItem.id} sx={{ alignItems: 'flex-start' }}>
                <Box
                  sx={{
                    p: 1,
                    width: '100%',
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            color: '#303f9f',
                            ml: 1,
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          onClick={() => {
                            dispatch({
                              type: 'CASE_DETAIL_SUCCESS',
                              payload: caseItem,
                            });
                            navigate('/app/case-detail');
                          }}
                        >
                          {textHighlight(
                            formatCaseTitle(caseItem.Title),
                            secondarySearchValue
                          )}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center',
                            color: '#021657',
                            p: 1,
                          }}
                        >
                          <Typography variant="body2">
                            {textHighlight(caseItem.Year, secondarySearchValue)}
                          </Typography>
                          <Typography variant="body2">
                            &bull;{' '}
                            {textHighlight(
                              caseItem.Section,
                              secondarySearchValue
                            )}
                          </Typography>
                          <Typography variant="body2">
                            &bull; {formatCourtName(caseItem.Court)}
                          </Typography>
                        </Box>
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 5,
                            overflow: 'hidden',
                            bgcolor: '#f8f8fb',
                            p: '8px 8px 0px 8px',
                            mt: 1,
                            borderRadius: '4px',
                          }}
                        >
                          {textHighlight(
                            caseItem.Case_Description,
                            secondarySearchValue
                          )}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mt: 1,
                            gap: 2,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                              <ReadMoreIcon />
                            </IconButton>
                            <Typography
                              variant="body2"
                              sx={{
                                mr: 0.5,
                                color: '#303f9f',
                                cursor: 'pointer',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                              onClick={() => {
                                dispatch({
                                  type: 'CASE_DETAIL_SUCCESS',
                                  payload: caseItem,
                                });
                                navigate('/app/case-detail');
                              }}
                            >
                              Read more
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ p: 0 }}>
                              <TurnedInNotIcon fontSize="small" />
                            </IconButton>
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 0.5,
                                color: '#303f9f',
                                cursor: 'pointer',
                                '&:hover': {
                                  textDecoration: 'underline',
                                },
                              }}
                            >
                              Bookmark
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    }
                  />
                  <Divider />
                </Box>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Pagination
              count={Math.ceil(filteredResults.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                mt: 2,
                '& .MuiPaginationItem-root': {
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#303f9f',
                  },
                  '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: '#213165',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                    },
                  },
                },
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SearchResults;
