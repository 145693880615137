import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress, Snackbar, Alert, Divider, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MuiTelInput } from 'mui-tel-input';
import { signUp } from '../actions/userActions'; 

const SignUpModal = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [designation, setDesignation] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success'); 
  const dispatch = useDispatch();

  const userSignup = useSelector((state) => state.userSignup);
  const { message, error } = userSignup;


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName || !lastName || !email || !phoneNumber || !designation || !username || !password || !confirmPassword) {
      setToastSeverity('error');
      setToastText('Please fill in all fields');
      setOpenToast(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setToastSeverity('error');
      setToastText('Please enter a valid email address');
      setOpenToast(true);
      return;
    }

    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setToastSeverity('warning');
      setToastText('Password must be at least 8 characters long and contain at least one numeric character');
      setOpenToast(true);
      return;
    }


    if (password !== confirmPassword) {
      setToastSeverity('warning');
      setToastText('Passwords do not match');
      setOpenToast(true);
      return;
    }

     setIsLoading(true);
    try {
      // const successMessage =  dispatch(signUp(firstName, lastName, email, phoneNumber, designation, username, password));
     await dispatch(signUp(firstName, lastName, email, phoneNumber, designation, username, password));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  useEffect(() => {
    if (message) {
      if (message === 'Username already exists.' || message === 'Email already exists.' || message === 'Phone number already exists.') {
        setToastSeverity('info');
      } else {
        setToastSeverity('success');
        handleClose();
      }
        setToastText(message);
        setOpenToast(true);
        dispatch ({type:'USER_SIGNUP_RESET'})
      } 
      if (error){
        setToastSeverity('error');
      setToastText(error);
      setOpenToast(true);
      }
  }, [message,error,handleClose,dispatch]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle variant='h5' sx={{display:'flex',justifyContent:'center',color: '#003D78',
              fontWeight: 'bold',}}>Sign Up</DialogTitle>
              <Divider/>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                placeholder="Enter your first name"
                variant="outlined"
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Email"
                placeholder="Enter your email"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
               <TextField
                fullWidth
                label="Username"
                placeholder="Enter your username"
                variant="outlined"
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                fullWidth
                label="Password"
                placeholder="Enter your password"
                variant="outlined"
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
             
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                label="Last Name"
                placeholder="Enter your last name"
                variant="outlined"
                margin="normal"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
               <MuiTelInput
                defaultCountry="PK"
                value={phoneNumber}
                onChange={setPhoneNumber}
                fullWidth
                label="Phone Number"
                variant="outlined"
                margin="normal"
                required
              />
               <TextField
                fullWidth
                label="Designation"
                placeholder="Enter your designation"
                variant="outlined"
                margin="normal"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
              
              <TextField
                fullWidth
                label="Confirm Password"
                placeholder="Confirm your password"
                variant="outlined"
                margin="normal"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{mr:2}}>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button variant='contained' onClick={handleSubmit} sx={{backgroundColor:'#003D78'}} disabled={isLoading}>
            {isLoading ? <CircularProgress size={22} /> : 'Sign Up'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openToast}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          sx={{ width: '100%' }}
          severity={toastSeverity}
          variant="filled"
          onClose={handleCloseSnackbar}
        >
          {toastText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignUpModal;
