import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, IconButton, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchResults } from '../actions/searchActions';

const LegalSearch2 = ({ updateSearchBar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMode, setSelectedMode] = useState('caseLaw');
  const [userQuery, setUserQuery] = useState('');

  const handleButtonClick = (value) => {
    setSelectedMode(value);
  };

  const handleSearch = () => {
    // const baseUrl = 'https://rg-law-gpt-ai-search-v3.search.windows.net';
    // const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';
    // const url = `${baseUrl}/indexes/case-law-index-v2/docs/search?api-version=2024-05-01-preview`;
    // const requestBody = {
    //   count: true,
    //   skip: 0,
    //   top: 50,
    //   searchMode: 'any',
    //   queryType: 'simple',
    //   facets: [
    //     'Court,count:5,sort:count',
    //     'Year,count:5,sort:count',
    //     'Section,count:5,sort:count',
    //   ],
    //   search: userQuery,
    // };
    // fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'api-key': apiKey,
    //   },
    //   body: JSON.stringify(requestBody),
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok' + response.statusText);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => console.log(data))
    //   .catch((error) => console.log(error));
    if (!userQuery) {
      setUserQuery('*');
      dispatch(searchResults('*'));
      navigate('/app/searchResults');
    } else {
      dispatch(searchResults(userQuery));
      updateSearchBar(selectedMode, userQuery);
      navigate('/app/searchResults');
    }
  };
  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChange = (e) => {
    setUserQuery(e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        minHeight: '95.5vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //height: '86vh',
        //flex: 5,
        p: 2,
        //overflowY: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: '24px' }}>
        <img
          src="/images/legalSearchLogo2.png"
          alt="product logo"
          style={{
            maxWidth: '180px',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '60%',
          bgcolor: '#fff',
          borderRadius: '12px',
        }}
      >
        <TextField
          variant="outlined"
          autoFocus
          placeholder="What are you looking for?"
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              paddingRight: '0px',
            },
          }}
          InputProps={{
            endAdornment: (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: '#303f9f',
                  width: '7%',
                  height: '56px',
                  borderEndEndRadius: '12px',
                  borderTopRightRadius: '12px',
                }}
              >
                <IconButton onClick={handleSearch}>
                  <SearchIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Box>
            ),
          }}
          onKeyDown={handleEnter}
          onChange={handleChange}
          value={userQuery}
        />
      </Box>
      <Box sx={{ display: 'flex', marginTop: '24px', gap: 1.5 }}>
        <Button
          variant="contained"
          onClick={() => handleButtonClick('caseLaw')}
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
            backgroundColor: '#F5F5F5',
            color: 'black',
            '&:hover': {
              border: '1px solid darkgray',
              backgroundColor: '#F5F5F5',
              boxShadow: 'none',
            },
            border:
              selectedMode === 'caseLaw'
                ? '1px solid #303f9f'
                : '1px solid transparent',
          }}
        >
          Case Laws
        </Button>
        <Button
          disabled
          variant="contained"
          onClick={() => handleButtonClick('statutes')}
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
            backgroundColor: '#F5F5F5',
            color: 'black',
            '&:hover': {
              border: '1px solid darkgray',
              backgroundColor: '#F5F5F5',
              boxShadow: 'none',
            },
            border:
              selectedMode === 'statutes'
                ? '1px solid #303f9f'
                : '1px solid transparent',
          }}
        >
          Statutes
        </Button>
      </Box>
    </Box>
  );
};

export default LegalSearch2;
