import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const CaseDetail2 = ({ selectedSection }) => {
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '86.5vh',
        flex: 5,
        p: 2,
        alignItems: 'center',
        bgcolor: '#f5f5f5',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '2px 4px 8px 0px #00000040',
          border: '1px solid grey',
          marginBottom: 'auto',
          bgcolor: 'white',
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            p: 3,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {caseItem?.Title}
            </Typography>
          </Box>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {caseItem?.Court}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {caseItem?.Year}
          </Typography>
        </Box> */}
        <Box
          sx={{
            p: 3,
          }}
        >
          {selectedSection === 'Case Description' && (
            <>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
              >
                <Typography variant="h6" sx={{ mr: 2, fontWeight: 'bold' }}>
                  Case Description
                </Typography>
              </Box>
              <Box
                sx={{ mb: 2 }}
                dangerouslySetInnerHTML={{
                  __html: caseItem?.Case_Description_HTML,
                }}
              />
            </>
          )}
          {selectedSection === 'Head Notes' && (
            <>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
              >
                <Typography variant="h6" sx={{ mr: 2, fontWeight: 'bold' }}>
                  Head Notes
                </Typography>
              </Box>
              <Box
                sx={{ mb: 2 }}
                dangerouslySetInnerHTML={{
                  __html: caseItem?.Head_Notes_HTML,
                }}
              />
            </>
          )}
          {/* <Typography
            ref={refs.caseSummaryRef}
            variant="h6"
            sx={{ mb: 2, fontWeight: 'bold' }}
          >
            Case Summary
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 1)', mb: 2 }}>
            Will be added yet.
          </Typography>
          <Typography
            ref={refs.headNotesRef}
            variant="h6"
            sx={{ mb: 2, fontWeight: 'bold' }}
          >
            Head Notes
          </Typography>
          <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 1)' }}>
            More information
          </Typography>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Case Details
          </Typography>
          <Typography>{caseItem?.Case_Details[0]?.Case_Detail_Name}</Typography>{' '} */}
        </Box>
      </Box>
    </Box>
  );
};

export default CaseDetail2;
