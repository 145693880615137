import React, { useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Box, Snackbar, Alert,Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MsAuth = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastSeverity, setToastSeverity] = useState('error');

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ['user.read'],
        redirectUri: `${window.location.origin}/app/legal-chat`,
      });

      const accessToken = loginResponse.accessToken;

      const userProfileResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const userProfile = await userProfileResponse.json();

      const backendResponse = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createUserWithGoogle',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: userProfile.displayName,
            email: userProfile.userPrincipalName,
          }),
        }
      );

      const data = await backendResponse.json();

      if (data.message && data.message === 'User is not active. Please contact support') {
        setToastSeverity('info');
        setToastText('User is not active. Please contact support');
        setOpenToast(true);
      } else if (data.activeUser === false) {
        setToastSeverity('info');
        setToastText('User created but not active. Please contact support');
        setOpenToast(true);
      } else {
        localStorage.setItem('userInfo', JSON.stringify(data));
        dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data });
        navigate('/app/legal-chat');
      }
    } catch (error) {
      setToastSeverity('error');
      setToastText('An error occurred during Microsoft sign-in. Please try again.');
      setOpenToast(true);
      console.error(error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        backgroundColor: '#fff',
        border: '1px solid #dadce0',
        borderRadius: '3px',
        cursor: 'pointer',
      }}
      onClick={handleMicrosoftLogin}
    >
      <img src="/images/ms_logo.png" alt="microsoft" width="20px" height="20px" />
      <Button
       sx={{
        display: 'flex',
        textTransform: 'none',
        color:'black'
      }}
        type="button"
      >
        Sign in with Microsoft
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openToast}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
      >
        <Alert sx={{ width: '100%' }} severity={toastSeverity} variant="filled" onClose={handleCloseSnackbar}>
          {toastText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MsAuth;
