import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import { login } from '../actions/userActions';
import GoogleAuth from '../components/GoogleAuth';
import { trackEvent } from '../analytics';
import MsAuth from '../components/MsAuth';
import { Stack } from '@mui/system';
import SignUpModal from '../components/SignUpModal';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error } = userLogin;

  const handleClose = () => {
    setOpenToast(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username.trim() === '' || password.trim() === '') {
      setToastText('Please enter your credentials');
      setOpenToast(true);
      trackEvent('Form', 'Empty Credentials', 'Empty Credentials');
      return;
    }
    setisLoading(true);
    dispatch(login(username, password));
    trackEvent('Form', 'Login Attempt', 'Login Attempt');
  };

  useEffect(() => {
    if (userInfo?.status === 'success') {
      if (!userInfo.activeUser) {
        setisLoading(false);
        setToastText('User is not active. Please contact support.');
        setOpenToast(true);
      } else {
        trackEvent('Form', 'Successful Login', `User: ${userInfo.name}`);
        setisLoading(false);
        navigate('/home');
      }
    } else if (error) {
      setisLoading(false);
      setToastText(error);
      setOpenToast(true);
      trackEvent('Form', 'Login Failure', `User: ${userInfo?.name}`);
    }
  }, [userInfo, error, navigate]);

  const handleSignUpOpen = () => {
    setIsSignUpOpen(true);
  };

  const handleSignUpClose = () => {
    setIsSignUpOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundImage: "url('images/image.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '50%' },
          //flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            //width: { xs: '80%', md: '40%' },
            width: '45%',
            marginTop: '10%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3rem',
            borderRadius: '16px',
            position: 'relative',
            backgroundColor: 'white',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: '12px' }}>
            <img
              src="/images/legalSearchLogo2.png"
              alt="product logo"
              style={{
                maxWidth: '150px',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </Box>
          <TextField
            fullWidth
            label="Username"
            placeholder="Enter your username"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            //helperText="Please input username"
          />
          <TextField
            fullWidth
            label="Password"
            placeholder="Enter your password"
            variant="outlined"
            margin="normal"
            type="password"
            InputLabelProps={{ shrink: true }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            //helperText="Please input password"
          />
          <Button
            size="large"
            fullWidth
            sx={{
              display: 'flex',
              fontWeight: 'bold',
              backgroundColor: '#101936',
              marginTop: '0.5rem',
              textTransform: 'none',
              '&:hover': { backgroundColor: '#003D78' },
              transition: 'background-color 0.3s ease-in-out',
            }}
            variant="contained"
            type="submit"
          >
            {isLoading ? <CircularProgress size={22} /> : 'Sign In'}
          </Button>
          <Stack
            direction="column"
            gap={2}
            marginTop={2}
            marginBottom={2}
            width="100%"
          >
            <GoogleAuth />
            <MsAuth />
          </Stack>
          <Typography variant="subtitle2">
            Don't have an account?{' '}
            <Button
              onClick={handleSignUpOpen}
              sx={{ fontWeight: 'bold', color: '#003D78' }}
            >
              Sign Up!
            </Button>
          </Typography>
        </Box>
      </Box>

      {(error || openToast) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openToast}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert
            sx={{ width: '100%' }}
            severity="error"
            variant="filled"
            color="error"
            onClose={handleClose}
          >
            {toastText}
          </Alert>
        </Snackbar>
      )}
      <SignUpModal open={isSignUpOpen} handleClose={handleSignUpClose} />
    </Box>
  );
}

export default LoginPage;
