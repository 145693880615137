import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { userLoginReducer, userSignUpReducer } from './reducers/userReducers';
import {
  chatHistoryReducer,
  chatTitlesReducer,
  checkServerReducer,
  deleteChatReducer,
  editTitleReducer,
  newChatReducer,
} from './reducers/chatReducers';
import {
  deletePetitionReducer,
  editPetitionTitleReducer,
  newPetitionReducer,
  petitionHistoryReducer,
  petitionTitlesReducer,
} from './reducers/petitionReducers';
import {
  caseDetailReducer,
  searchResultsReducer,
} from './reducers/searchReducers';

const reducer = {
  userLogin: userLoginReducer,
  userSignup: userSignUpReducer,
  chatTitles: chatTitlesReducer,
  petitionTitles: petitionTitlesReducer,
  newChat: newChatReducer,
  newPetition: newPetitionReducer,
  chatHistory: chatHistoryReducer,
  petitionHistory: petitionHistoryReducer,
  editChat: editTitleReducer,
  editPetitionTitle: editPetitionTitleReducer,
  deleteChat: deleteChatReducer,
  deletePetition: deletePetitionReducer,
  serverStatus: checkServerReducer,
  searchResults: searchResultsReducer,
  caseDetail: caseDetailReducer,
};
const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const preloadedState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer,
  middleware,
  preloadedState,
});

export default store;
