import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Divider, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
//import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
//import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  editChatTitle,
  getChatHistory,
  deleteChat,
  getChatTitles,
} from '../../actions/chatActions';

const Sidebar = ({ isVisible }) => {
  const dispatch = useDispatch();

  const [selectedChat, setSelectedChat] = useState(-1);
  const [chatTitlesList, setChatTitlesList] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const chatTitles = useSelector((state) => state.chatTitles);
  const { titles, error } = chatTitles;

  const [isEditing, setIsEditing] = useState(titles?.map(() => false));

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chatHistory = useSelector((state) => state.chatHistory);
  const { history } = chatHistory;

  const editChat = useSelector((state) => state.editChat);
  const { success: successEdit } = editChat;

  const deleteChatStatus = useSelector((state) => state.deleteChat);
  const { success: successDelete } = deleteChatStatus;

  const handleListItemClick = (index, chatId) => {
    setSelectedChat(index);
    dispatch(getChatHistory(userInfo._id, chatId));
  };

  const handleNewChatClick = () => {
    setSelectedChat(-1);
    dispatch({ type: 'CHAT_HISTORY_RESET' });
    dispatch({ type: 'NEW_CHAT_SUCCESS' });
  };

  const handleEditTitle = async (index) => {
    setNewTitle(chatTitlesList[index].chat_title);
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = true;
      return newEditingState;
    });
  };

  const handleSaveEdit = (index) => {
    dispatch(editChatTitle(userInfo._id, history.chat_id, newTitle));
    setNewTitle('');
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = false;
      return newEditingState;
    });
  };

  const handleCancelEdit = (index) => {
    setNewTitle('');
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = false;
      return newEditingState;
    });
  };

  const handleDeleteChat = (event) => {
    event.stopPropagation();
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this chat?'
    );
    if (userConfirmed) {
      dispatch(deleteChat(userInfo._id, history.chat_id));
      dispatch({ type: 'CHAT_HISTORY_RESET' });
      setSelectedChat(-1);
    }
  };

  useEffect(() => {
    if (titles) {
      setIsLoading(false);
      setChatTitlesList(titles);
      const initialEditingState = titles.map(() => false);
      setIsEditing(initialEditingState);
    }
    if (error) {
      toast.error(error, { position: 'top-right' });
    }
  }, [titles, error, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getChatTitles(userInfo?._id));
  }, [successEdit, successDelete, userInfo, dispatch]);

  return (
    <Box
      sx={{
        display: { xs: isVisible ? 'flex' : 'none', sm: 'flex' },
        flexDirection: 'column',
        flex: 1,
        minWidth: '278px',
        height: '98.9vh',
        p: '8px 8px 0 8px',
        bgcolor: '#F5F5F5',
      }}
    >
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{
          border: '1px dashed #101936',
          //borderColor: '#101936',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '8px',
          marginTop: '8px',
          color: '#101936',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#303f9f',
            color: '#fff',
          },
        }}
        onClick={handleNewChatClick}
      >
        New Chat
      </Button>
      {isLoading ? (
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={22} />
        </Box>
      ) : chatTitlesList.length > 0 ? (
        <List sx={{ overflow: 'auto',   '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        }, }}>
          {chatTitlesList?.map((title, index) => (
            <>
              <ListItem
                key={uuidv4()}
                disablePadding
                sx={{
                  marginBottom: '6px',
                  marginTop: '6px',
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: '8px',
                    '&.Mui-selected': {
                      backgroundColor: '#101936',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#303f9f',
                      },
                    },
                  }}
                  selected={selectedChat === index}
                  onClick={() => handleListItemClick(index, title.chat_id)}
                >
                  {isEditing[index] ? (
                    <Stack direction="row">
                      <input
                        style={{ width: '100%', marginLeft: '0' }}
                        type="text"
                        autoFocus
                        value={newTitle}
                        onChange={(e) => {
                          e.stopPropagation();
                          setNewTitle(e.target.value);
                        }}
                      />
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveEdit(index);
                        }}
                      >
                        <DoneIcon fontSize="small" sx={{ color: 'white' }} />{' '}
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelEdit(index);
                        }}
                      >
                        <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent={
                        selectedChat === index ? 'center' : 'flex-start'
                      }
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        noWrap
                        width={selectedChat === index ? '80%' : '100%'}
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {title.chat_title}
                      </Typography>
                      {selectedChat === index && (
                        <Stack
                          width="20%"
                          direction="row"
                          display="flex"
                          gap="0"
                          //justifyContent="flex-end"
                        >
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditTitle(index);
                            }}
                          >
                            <EditIcon
                              fontSize="small"
                              sx={{ color: 'white' }}
                            />{' '}
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDeleteChat(e)}
                          >
                            <DeleteIcon
                              fontSize="small"
                              sx={{ color: 'white' }}
                            />
                          </IconButton>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: '90%', margin: '0 auto' }} />
            </>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            m: 'auto',
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            textAlign="center"
          >
            You have no previous chats.
            <br />
            Start a new conversation! 🚀
          </Typography>
        </Box>
      )}
      {/* <Box
        display="flex"
        marginTop="auto"
        justifyContent="center"
        alignItems="center"
        borderTop="1px dashed #101936"
      >
        <footer
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#6C6C6C',
            fontWeight: 'bold',
            gap: '3px',
            cursor: 'pointer',
          }}
        >
          <Typography variant="subtitle2">Powered By:</Typography>
          <a href="https://digifloat.com/" rel="noreferrer" target="_blank">
            <Typography>
              <span style={{ color: '#E60E0F' }}>Digi</span>
              <span style={{ color: '#373536' }}>float</span>
            </Typography>
            {/* <img
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '77.2px',
                height: '20px',
              }}
              src="/images/df_logo.png"
              alt="df-logo"
            /> 
          </a>
        </footer>
      </Box> */}
      <ToastContainer />
    </Box>
  );
};

export default Sidebar;
