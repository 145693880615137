export const searchResultsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SEARCH_RESULTS_REQUEST':
      return { loading: true };
    case 'GET_SEARCH_RESULTS_SUCCESS':
      return { loading: false, results: action.payload };
    case 'GET_SEARCH_RESULTS_FAIL':
      return { loading: false, error: action.payload };
    case 'GET_SEARCH_RESULTS_RESET':
      return {};
    default:
      return state;
  }
};

export const caseDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CASE_DETAIL_REQUEST':
      return { loading: true };
    case 'CASE_DETAIL_SUCCESS':
      return { loading: false, caseItem: action.payload };
    case 'CASE_DETAIL_FAIL':
      return { loading: false, error: action.payload };
    case 'CASE_DETAIL_RESET':
      return {};
    default:
      return state;
  }
};
