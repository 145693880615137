import React from 'react';
import { Box, Avatar, Typography, useTheme } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import { pink } from '@mui/material/colors';

const ChatModeBox = ({ text, isSelected, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        border: isSelected
          ? `2px solid ${theme.palette.primary.main}`
          : '1px solid gray',
        borderRadius: 2,
        width: '100%',
        height: 'auto',
        padding: 2,
        mr: '6px',
        transition: 'border 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          borderColor: theme.palette.primary.dark,
          boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
        },
        '&:active': {
          borderColor: theme.palette.primary.dark,
        },
      }}
    >
      {/* <Avatar sx={{ bgcolor: pink[500], mb: 1 }}> */}
      {text === 'Freeform Legal Inquiry' && (
        <img
          src="/images/freeform.png"
          alt="free form query"
          width="30px"
          height="auto"
        />
      )}
      {text === 'Structured Query' && (
        <img
          src="/images/structured.png"
          alt="free form query"
          width="25px"
          height="auto"
        />
      )}
      {text === 'Case Law Query' && (
        <img
          src="/images/caseLaw.png"
          alt="free form query"
          width="30px"
          height="auto"
        />
      )}

      {/* </Avatar> */}
      <Typography variant="body1" sx={{ textAlign: 'center', mt: 1 }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ChatModeBox;
