import React, { useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  Typography,
  Collapse,
  ListItemButton,
  IconButton,
  Divider
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

const CaseDetailSidebar2 = ({ onSectionClick }) => {
  const [openCaseDetails, setOpenCaseDetails] = useState(true);
  const [openCaseSummary, setOpenCaseSummary] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);


  const handleCaseDetailsClick = () => {
    setOpenCaseDetails(!openCaseDetails);
  };

  const handleCaseSummaryClick = () => {
    setOpenCaseSummary(!openCaseSummary);
  };

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked); 
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minWidth: '300px',
        width: '300px',
        borderRight: '1px solid grey',
        overflowY: 'auto',
        height: '91vh',
        bgcolor: 'white',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton
          sx={{
            color: '#213165',
            fontSize: '24px',
          }}
          onClick={() => navigate('/app/searchResults')}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Back
        </Typography>
      </Box>
        <List>
        <ListItemButton sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={handleBookmarkClick}>
          <ListItemText primary={<Typography sx={{ fontWeight: 'bold' }}>Bookmark this case</Typography>} />
          {isBookmarked ? <TurnedInIcon /> : <TurnedInNotIcon />}
        </ListItemButton>
      </List>
      <Divider/>
      <List>
        <ListItemButton onClick={handleCaseDetailsClick}>
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: 'bold' }}>Case Details</Typography>
            }
          />
          {openCaseDetails ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCaseDetails} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => onSectionClick('Case Description')}
            >
              <ListItemText primary="Case Description" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => onSectionClick('Head Notes')}
            >
              <ListItemText primary="Head Notes" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton disabled onClick={handleCaseSummaryClick}>
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: 'bold' }}>Case Summary</Typography>
            }
          />
          {openCaseSummary ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>
    </Box>
  );
};

export default CaseDetailSidebar2;
