import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { CircularProgress, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  deletePetition,
  editPetitionTitle,
  getPetitionHistory,
  getPetitionTitles,
} from '../../actions/petitionActions';

const PetitionSidebar = ({ isVisible }) => {
  const dispatch = useDispatch();

  const [selectedPetition, setSelectedPetition] = useState(-1);
  const [petitionList, setPetitionList] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const petitionTitles = useSelector((state) => state.petitionTitles);
  const { titles, error } = petitionTitles;

  const [isEditing, setIsEditing] = useState(titles?.map(() => false));

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const petitionHistory = useSelector((state) => state.petitionHistory);
  const { history } = petitionHistory;

  const editPetition = useSelector((state) => state.editPetitionTitle);
  const { success: successEdit } = editPetition;

  const deletePetitionStatus = useSelector((state) => state.deletePetition);
  const { success: successDelete } = deletePetitionStatus;

  const handleListItemClick = (index, chatId) => {
    setSelectedPetition(index);
    dispatch(getPetitionHistory(userInfo._id, chatId));
  };

  const handleNewChatClick = () => {
    setSelectedPetition(-1);
    dispatch({ type: 'PETITION_HISTORY_RESET' });
    dispatch({ type: 'NEW_PETITION_SUCCESS' });
  };

  const handleEditTitle = async (index) => {
    setNewTitle(petitionList[index].chat_title);
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = true;
      return newEditingState;
    });
  };

  const handleSaveEdit = (index) => {
    dispatch(editPetitionTitle(userInfo._id, history.chat_id, newTitle));
    setNewTitle('');
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = false;
      return newEditingState;
    });
  };

  const handleCancelEdit = (index) => {
    setNewTitle('');
    setIsEditing((prevState) => {
      const newEditingState = [...prevState];
      newEditingState[index] = false;
      return newEditingState;
    });
  };

  const handleDeleteChat = (event) => {
    event.stopPropagation();
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this chat?'
    );
    if (userConfirmed) {
      dispatch(deletePetition(userInfo._id, history.chat_id));
      dispatch({ type: 'CHAT_HISTORY_RESET' });
      setSelectedPetition(-1);
    }
  };

  useEffect(() => {
    if (titles) {
      setIsLoading(false);
      setPetitionList(titles);
      const initialEditingState = titles.map(() => false);
      setIsEditing(initialEditingState);
    }
    if (error) {
      toast.error(error, { position: 'top-right' });
    }
  }, [titles, error, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPetitionTitles(userInfo?._id));
  }, [successEdit, successDelete, userInfo, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1.2,
        height: '98vh',
        minWidth: '278px',
        p: '12px 8px 0 8px',
        bgcolor: '#F5F5F5',
        //borderRight: '1px solid #ccc',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '2px',
          marginTop: '2px',
          border: '1px dashed #101936',
          color: '#101936',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#303f9f',
            color: '#fff',
          },
        }}
        onClick={handleNewChatClick}
      >
        New Petition
      </Button>
      {isLoading ? (
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={22} />
        </Box>
      ) : petitionList.length > 0 ? (
        <List sx={{ overflowY: 'auto', flexGrow: 1 }}>
          {petitionList?.map((title, index) => (
            <>
              <ListItem
                key={index}
                disablePadding
                sx={{ marginBottom: '6px', marginTop: '6px' }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: '8px',
                    '&.Mui-selected': {
                      backgroundColor: '#101936',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#303f9f',
                      },
                    },
                  }}
                  selected={selectedPetition === index}
                  onClick={() => handleListItemClick(index, title.chat_id)}
                >
                  {/* <ListItemIcon>
                <QuestionAnswerIcon fontSize="small" />
              </ListItemIcon> */}
                  {isEditing[index] ? (
                    <Stack direction="row">
                      <input
                        style={{ width: '100%', marginLeft: '0' }}
                        type="text"
                        autoFocus
                        value={newTitle}
                        onChange={(e) => {
                          e.stopPropagation();
                          setNewTitle(e.target.value);
                        }}
                      />
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveEdit(index);
                        }}
                      >
                        <DoneIcon fontSize="small" sx={{ color: 'white' }} />{' '}
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelEdit(index);
                        }}
                      >
                        <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent={
                        selectedPetition === index ? 'center' : 'flex-start'
                      }
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle2"
                        noWrap
                        width={selectedPetition === index ? '80%' : '100%'}
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {title.chat_title}
                      </Typography>
                      {selectedPetition === index && (
                        <Stack
                          width="20%"
                          direction="row"
                          display="flex"
                          gap="0"
                          //justifyContent="flex-end"
                        >
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditTitle(index);
                            }}
                          >
                            <EditIcon
                              fontSize="small"
                              sx={{ color: 'white' }}
                            />{' '}
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDeleteChat(e)}
                          >
                            <DeleteIcon
                              fontSize="small"
                              sx={{ color: 'white' }}
                            />
                          </IconButton>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: '90%', margin: '0 auto' }} />
            </>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            m: 'auto',
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            textAlign="center"
          >
            You have no previous petitions.
            <br />
            Generate a new Petition! 🚀
          </Typography>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default PetitionSidebar;
