import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import store from './store';
import LoginPage from './pages/LoginPage';
import ChatInterface from './components/ChatInterface';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './config/msAuthConfig';
import { MsalProvider } from '@azure/msal-react';
import { initializeGA, trackPageView } from './analytics';
import NotFound from './components/NotFound';
import PetitionForm from './components/PetitionForm';
//import LegalSearch from './components/LegalSearch';
import CaseDetail from './components/CaseDetail';
import LegalSearch2 from './components/LegalSearch2';
import SearchResults from './components/SearchResults';
import LegalChatHomePage from './pages/LegalChatHomePage';
import HomePage from './pages/HomePage';

const root = ReactDOM.createRoot(document.getElementById('root'));

// msal instance
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

// Set the account on successful login
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult = event.payload;
    const account = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});

// Enable account storage events
msalInstance.enableAccountStorageEvents();

const TrackPageViews = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const handleResponse = async (response) => {
      if (response && response.account) {
        const { name, username } = response.account;
        try {
          const res = await fetch(
            'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createUserWithGoogle',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ name, email: username }),
            }
          );
          const data = await res.json();
          localStorage.setItem('userInfo', JSON.stringify(data));
          dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data });
          <Navigate to="/app/chat" />;
        } catch (error) {
          console.log('Failed to register user:', error);
        }
      }
    };
    msalInstance.handleRedirectPromise().then(handleResponse);

    // Check if the user is already signed in
    const account = msalInstance.getActiveAccount();
    if (account) {
      // User is already signed in, navigate to the app
      handleResponse({ account });
    }
  }, [dispatch]);

  return children;
};

initializeGA();

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId="168527565999-ku8psofqjgsf673vh38ekf94rns19sv2.apps.googleusercontent.com">
        <React.StrictMode>
          <Router>
            <TrackPageViews>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/app" element={<App />}>
                  <Route
                    path="/app/legal-chat"
                    element={<LegalChatHomePage />}
                  />
                  <Route path="/app/case-law" element={<LegalSearch2 />} />
                  <Route
                    path="/app/searchResults"
                    element={<SearchResults />}
                  />
                  <Route path="/app/case-detail" element={<CaseDetail />} />
                  <Route path="/app/petition" element={<PetitionForm />} />
                  <Route path="/app/case-management" element={<NotFound />} />
                </Route>
                {/* <Route path="*" element={<Navigate to="/" />} /> */}
              </Routes>
            </TrackPageViews>
          </Router>
        </React.StrictMode>
      </GoogleOAuthProvider>
    </MsalProvider>
  </Provider>
);
