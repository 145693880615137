import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Divider, IconButton } from '@mui/material';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const CaseDetailRightSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchResults = useSelector((state) => state.searchResults);
  const { results } = searchResults;

  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [nextCases, setNextCases] = useState([]);

  function getNextCases(cases, caseItem) {
    // Find the index of the specific caseItem
    const index = cases.findIndex((c) => c.id === caseItem.id);

    // If the caseItem is found, return the next cases
    if (index !== -1 && index + 1 < cases.length) {
      return cases.slice(index + 1);
    }

    // If the caseItem is not found or it's the last item, return an empty array
    return [];
  }

  const formatCourtName = (courtName) => {
    return courtName
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatCaseTitle = (title) => {
    const splitTitle = title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  useEffect(() => {
    if (caseItem) {
      const relevantCases = getNextCases(results.value, caseItem);
      setNextCases(relevantCases);
    }
  }, [caseItem, results]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1.5,
        p: '10px 8px 0 8px',
        overflow: 'auto',
        borderLeft: '1px solid grey',
        height: '89.5vh',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        Relevant Cases
      </Typography>
      <Divider />
      {nextCases.map((caseItem, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '99%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                flex: 1,
                color: '#303f9f',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {formatCaseTitle(caseItem.Title)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              //alignItems: 'flex-start',
              color: '#021657',
              p: 1,
              gap: 1,
            }}
          >
            <Typography variant="subtitle2">{caseItem.Year}</Typography>
            <Typography variant="subtitle2">{caseItem.Section}</Typography>
            <Typography variant="subtitle2">
              {formatCourtName(caseItem.Court)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mt: 1,
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                <ReadMoreIcon />
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  mr: 0.5,
                  color: '#303f9f',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => {
                  dispatch({
                    type: 'CASE_DETAIL_SUCCESS',
                    payload: caseItem,
                  });
                  navigate('/app/case-detail');
                }}
              >
                Read more
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ p: 0 }}>
                <TurnedInNotIcon fontSize="small" />
              </IconButton>
              <Typography
                variant="body2"
                sx={{
                  ml: 0.5,
                  color: '#303f9f',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Bookmark
              </Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default CaseDetailRightSidebar;
